import React, { useContext } from "react";
import Layout from "../components/layout";
import Faq from "../components/faq/faq.component";
import LiveChat from "../components/live-chat/live-chat.component";
import FindYourBdm from "../components/find-your-bdm/find-your-bdm.component";
import GeneralAnchor from "../components/general-anchor/general-anchor.component";
import MoneyWorriesBtn from "../components/money-worries-btn/money-worries-btn.component";
import { MenuContext } from "../context";
import "../styles/app.scss";
import { Link } from "gatsby";
import { triggerTealiumEvent } from "../utils/tealiumUtils";
import { useLocation } from "@reach/router";

export default function BrokerSupport() {
  const menuContext = useContext(MenuContext);

  const location = useLocation();
  if (typeof window !== "undefined" && window.utag) {
    triggerTealiumEvent({
      tealiumEvent: "view",
      pageUrl: location.href,
      pageName: "broker support",
      pageType: "product information",
      pageSubCategory: "broker support",
      rawDataLayer: "10451v30",
    });
  }

  const handleClick = ({ sectionName, clickedText }) => {
    if (typeof window !== "undefined" && window.utag) {
      triggerTealiumEvent({
        tealiumEvent: "link",
        pageUrl: location.href,
        pageName: "broker support",
        eventCategory: "content",
        eventAction: "onsite",
        eventContent: `${sectionName} | broker support : ${clickedText}`,
        pageType: "product information",
        pageSubCategory: "broker support",
        rawDataLayer: "10451v33",
      });
    }
  };

  return (
    <>
      {/* <SupportBanner /> */}
      <Layout
        title="Broker support"
        metaDescription="Please refer to our Criteria page for all the information you will need on our lending policy, or for application updates please view case tracking on the broker platform."
      >
        <section className="broker-support-page page-section row">
          <div className="information-container col-xs-12 col-lg-8">
            <h1 className="page-title">Broker support</h1>
            <p>
              Please refer to our{" "}
              <Link to="/criteria" inert={menuContext.isMenuOpen ? "true" : null}>
                Criteria
              </Link>{" "}
              page for all the information you will need on our lending policy, or for application updates please view
              case tracking on the broker platform.
            </p>
            <p>
              If you still haven’t found what you are looking for and require further assistance, there are various ways
              you can get in touch; via our Chat facility, telephone or your local BDM.
            </p>
            <p>
              To help us to provide you with the best possible service, please ensure you contact us using one
              communication channel at any one time.
            </p>
            <br />

            <section className="find-your-bdm find-your-bdm--broker-support">
              <div className="affordability-calculator affordability-calculator--broker-support">
                <div className="affordability-calculator__buttons">
                  <div className="row">
                    <div className="col-md-6">
                      <GeneralAnchor
                        isBlank
                        href="/pdfs/Changes_to_your_registration_with_HSBC_UK.pdf"
                        rel="noreferrer"
                        isLink={true}
                      >
                        Change to registration details
                      </GeneralAnchor>
                    </div>
                    <div className="col-md-6">
                      <GeneralAnchor isBlank href="/pdfs/Application_Amendment_Form.pdf" rel="noreferrer" isLink={true}>
                        Amend an application
                      </GeneralAnchor>
                    </div>
                    <div className="col-md-6">
                      <GeneralAnchor
                        isBlank
                        href="https://www.hsbc.co.uk/mortgages/intermediaries/help"
                        rel="noreferrer"
                        isLink={true}
                      >
                        Amend a product on submitted application
                      </GeneralAnchor>
                    </div>
                    <div className="col-md-6">
                      <GeneralAnchor isBlank href="/pdfs/Application_Amendment_Form.pdf" rel="noreferrer" isLink={true}>
                        Request an offer extension
                      </GeneralAnchor>
                    </div>
                  </div>
                </div>
              </div>
              <p>If you would like to amend the date of a Product Switch, please call our Helpdesk, Line Option 4.</p>
              <p>
                We may not be able to amend an application once we have received a request for funds from the solicitor.
              </p>
            </section>
            <div className="live-chat-wrapper">
              <LiveChat
                onClick={(clickedTextText) => handleClick({ sectionName: "live chat", clickedText: clickedTextText })}
              />
            </div>
            <Faq />
          </div>
          <div className="support-container col-xs-12 col-lg-4">
            <FindYourBdm
              onClick={(clickedText) => handleClick({ sectionName: "find your bdm", clickedText: clickedText })}
            />
            <MoneyWorriesBtn
              onClick={(clickedText) => handleClick({ sectionName: "money worries", clickedText: clickedText })}
            />
            {/* <LatestNews newsItems={NewsData2023.rateChange} /> */}
            <FindYourBdm
              additionalClassname="green"
              onClick={(clickedText) => handleClick({ sectionName: "accessibility and disability", clickedText: clickedText })}
            />
          </div>
        </section>
      </Layout>
    </>
  );
}
